import React from 'react';
import PropTypes from 'prop-types';
import { StoryWrapper } from './Story.styled';
import NavbarComponent from '../NavbarComponent'
import FooterComponent from '../FooterComponent'
import pic from './pic.jpg'

const Story = () => (
   <StoryWrapper data-testid="Story">
      <div className="App">
         <div className="body">
            <NavbarComponent />
            <img src={pic} className="pic" alt="Story" />
            <span className="story">
               <p>
                  2005年仲夏，一班大男孩不約而同到訓練班打球，他們都熱愛棒球，但他們每個人背後都有著不同的故事。他們有的來自多倫多；有的來自多倫多；有的來自多倫多．．．．．．數著數著，原來有十三個人都是在多倫多長大的。「不如組隊一起參加聯賽啦。」就這樣，他們就為組軍參賽這個夢想踏出了第一步，並為球隊取名為＜西九＞，意為球場上的九個人都來自千里之外的西方。
               </p>
               <p>
                  命運安排了十三個楓葉國的男孩在香港相遇，也安排了新秀賽在深秋舉行。順理成章，＜西九＞第一場比賽就是新秀賽。＜西九＞部分球員在棒球場上馳騁多年，經驗不少，算是新秀賽中實力不俗的隊伍。可惜他們第一場就輸掉了，在這漫天落葉之中，黯然結束這新秀賽之旅。不過他們並沒有從此放棄，而且有了更遠大的目標，那就是來季的乙組公開賽。
               </p>
               <p>
                  ＜西九＞所過的第一個球季並不差，該勝出的比賽都順利勝出，可能實力上跟其餘幾支勁旅相差很大，球隊彷彿被冰封著，沒力繼續向上前進。
               </p>
               <p>為突破這個困境，球隊下了一個很重要的決定－－聘請教練進行正式訓練。恰巧正在樂富訓練班執教的安橋教練跟球球的理念一致，大家一拍即合。就這樣球隊邀請了安橋作為球隊教練，並在那年的平安夜開始了＜西九＞的第一課操練。從這天開始，隊員無論在技術、組織、合作、默契、進攻、防守等能力都有所改進，當中林錦華考上了計分員資格，陳德林考上了教練資格，殷偉良更成為乙組頭五名安打率最高的球員。加上劉志明、區偉雄、葉嘉亮等具教練資歷的球員加入，球隊的成績逐步改善，終於能夠在乙組站穩陣腳。
               </p>
               <p>
                  經過多年來的努力，＜西九＞廣納了不少棒球愛好者，以及訓練了一批又一批的新手，現時已經變成一個坐擁三隊球隊，共五十多名活躍球員的球會，在 2011 年更邀得楠Sir、堅Sir及傑 Sir 等多位資深教練加盟，提昇了球隊的訓練量及訓練質素，該年二隊勇奪男子乙二組的第二名，一隊亦差點取得男子乙一組決賽的入場卷，到晒草灣爭奪冠軍及來年的甲組席位。
               </p>
               <p>結局會怎麼樣？他們也不知道。只肯定往後的故事，還得他們繼續努力去編寫。
               </p>
               <p>
                  （待續）
               </p>
            </span>
         </div>
         <div className="footer">
            <FooterComponent />
         </div>
      </div>
   </StoryWrapper>
);

Story.propTypes = {};

Story.defaultProps = {};

export default Story;
