import React from 'react';
import PropTypes from 'prop-types';
import { ContactUsWrapper } from './ContactUs.styled';
import NavbarComponent from '../NavbarComponent'
import FooterComponent from '../FooterComponent'

const ContactUs = () => (
 <ContactUsWrapper data-testid="ContactUs">
    <div className="App">
      <div className="body">
         <NavbarComponent />
         <span className="content">歡迎透過以下電郵聯絡我們<br />
            Feel free to contact us by our email<br />
            電郵 Email: <a href="mailto:info@ninewesthk.com">info@ninewesthk.com</a>
         </span>
      </div>
      <div className="footer">
         <FooterComponent />
      </div>
   </div>
 </ContactUsWrapper>
);

ContactUs.propTypes = {};

ContactUs.defaultProps = {};

export default ContactUs;
