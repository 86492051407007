import React from 'react';
import { Minor2024Wrapper } from './Minor2024.styled';
import NavbarComponent from '../NavbarComponent'
import FooterComponent from '../FooterComponent'
import ImageGallery from "react-image-gallery";
import './Minor2024.css'

import img1 from './img/1.jpg'
import img2 from './img/2.jpg'
import img3 from './img/3.jpg'
import img4 from './img/4.jpg'
import img5 from './img/5.jpg'
import img6 from './img/6.jpg'
import img7 from './img/7.jpg'
import img8 from './img/8.jpg'

const images = [
   {
     original: img1,
     thumbnail: img1,
   },
   {
     original: img2,
     thumbnail: img2,
   },
   {
     original: img3,
     thumbnail: img3,
   },
   {
      original: img4,
      thumbnail: img4,
    },
    {
      original: img5,
      thumbnail: img5,
    },
    {
      original: img6,
      thumbnail: img6,
    },
    {
      original: img7,
      thumbnail: img7,
    },
    {
      original: img8,
      thumbnail: img8,
    },
 ];

const Minor2024 = () => (
   <Minor2024Wrapper data-testid="Minor2024">
      <div className="App">
         <div className="body">
            <NavbarComponent />
            <ImageGallery items={images} />;
            <span className="story">不經不覺，西九龍棒球會在香港少棒聯盟Minor Division中已領軍7個多月。今年共有18支參賽隊伍，包括本地華人隊伍🇭🇰以及其他由外籍球員（日本🇯🇵、韓國🇰🇷、美國🇺🇸）組成的隊伍。  經過初賽和復賽的16場激烈角逐，最終，我們再次站上了決賽場，迎接著爭奪冠軍的挑戰！🏆
               在長達120分鐘的比賽中，雙方展開了一場緊張激烈的較量，經過了五局的比賽後，我們成功衛冕✌🏻✌🏻。整場賽事雙方打得十分精彩，每個球員都拼盡全力，飛身防守，全力揮棒。縱使緊張到哭了出來，但他們都十分勇敢面對賽事，擦乾眼淚繼續戰鬥。也許，這就是棒球魅力之所在吧！🥳
               連續兩年奪冠，這個榮譽得來不易。每逢週末，球員們都在球場上度過，一直努力練習。爲了令自己的技術提昇，球員聽從教練的指示，縱使需要傳接上萬球，揮棒過萬次和跑過千次的跑壘練習，更要犧牲假日和娛樂的機會，但他們仍然樂在其中，未言辛苦👍🏻👍🏻👍🏻。感恩的是教練們一直陪伴在側，並且費盡心思安排練習。他們不僅傳授棒球技巧，更教導球員們應有的態度，帶領他們能夠在每次練習和比賽中不斷成長。🫶🏻🫡
               當然，那些勤勉參與每次訓練和比賽的家長更是功不可沒，他們身體力行，不懈努力地作為球員們最強的後盾。另外，背後還有一群默默付出的家長義工擔任隊媽、隊經理、記錄員、棒次檢查員、攝影師和協助訓練員，為球員和教練提供無微不至的支援。🙏🏻🙏🏻😘
               最後，🥰🥰感謝所有西九龍棒球會的成員和家人，感謝你們抽空前來現場支持並為球員加油打氣。你們的歡呼聲和鼓勵無不感染著所有球員，化作他們爭勝的動力，以此次冠軍作為對你們的回報。
               再次恭喜Ninewest Minor成員奪得2023-2024年度香港少棒聯盟Minor Division的總冠軍🏆🥇🎉！
               Go! Ninewest Go！</span>
         </div>
         <div className="footer">
            <FooterComponent />
         </div>
      </div>
   </Minor2024Wrapper>
);

Minor2024.propTypes = {};

Minor2024.defaultProps = {};

export default Minor2024;
