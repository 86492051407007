import React from 'react';
import PropTypes from 'prop-types';
import { NavbarComponentWrapper } from './NavbarComponent.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './ninewest_big_logo_298x75.png';

const NavbarComponent = () => {
   return (
      <>
         <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
            <Container>
               <Navbar.Brand href="/"><img src={logo} className="company-name" alt="West Kowloon Baseball Club" /></Navbar.Brand>
               <Navbar.Toggle aria-controls="basic-navbar-nav" />
               <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                     <Nav.Link href="/">主頁<br/ >Home</Nav.Link>
                     <Nav.Link href="/minor2024">最新消息<br />News</Nav.Link>
                     <Nav.Link href="/story">西九的故事<br />About NW</Nav.Link>
                     <Nav.Link href="/contact_us">聯絡我們<br />Contact Us</Nav.Link>
                     <Nav.Link target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSeiFTx5AwG-oHPca6az2iYWdkrY4F9KPO_rcHENeiaY4yjO9Q/viewform?c=0&w=1">加入我們<br />Join Us</Nav.Link>
                  </Nav>
               </Navbar.Collapse>
            </Container>
         </Navbar>
      </>
   );
}

NavbarComponent.propTypes = {};

NavbarComponent.defaultProps = {};

export default NavbarComponent;
