import './App.css';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import Minor2024 from './components/Minor2024';
import Story from './components/Story';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/minor2024" element={<Minor2024 />} />
          <Route path="/story" element={<Story />} />
          <Route path="/contact_us" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <div className="body-mask"></div>
      <App />
    </Suspense>
  );
}
