import styled from 'styled-components';

export const HomeWrapper = styled.div`
    .team-photo {
        width: 100%;
        display: block;
        margin: auto;
    }
    .team-story {
        display: inline-block;
        padding: 1em 5em;
    }
`;
