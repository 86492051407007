import styled from 'styled-components';

export const StoryWrapper = styled.div`
    .pic {
        width: 100%;
        display: block;
        margin: auto;
    }
    .story {
        display: inline-block;
        padding: 1em 5em;
    }
`;
