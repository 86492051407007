import React from 'react';
import { HomeWrapper } from './Home.styled';
import NavbarComponent from '../NavbarComponent'
import FooterComponent from '../FooterComponent'
import pic from './ninewest_2018.jpg';

const Home = () => {
   return (
      <HomeWrapper data-testid="Home">
         <div className="App">
            <div className="body">
               <NavbarComponent />
               <img src={pic} className="team-photo" alt="Team 2018" />
               <span className="team-story">「西九」的由來是2005年時球場上的九位隊員都來自西方國家，所以球隊取名為「西九」。早年西九只是一隊13人的球隊，及後開始定期訓練。每年均有不少想進一步認識棒球的朋友加入我們，亦有不少年輕時曾打棒球的外地訪客來港後透過我們重拾這兒時的玩意。終於在2010年，我們正式註冊為非牟利機構。經過了12年的努力，西九已經成為香港最大成人棒球會，擁有超過100名學員、8支隊伍。除了參加棒球總會舉辦的賽事，我們亦主辦了第六屆西九龍棒球會盃，吸引了另外七間球會參加，彼此都多了比賽交流的機會從而得到寶貴嘅經驗。練習方面，由最初差不多每一兩個月才一次的練習增加到現時平均每週均有一至兩次。本會希望為這些學生們度身訂造合適的訓練課程，除了教授棒球技巧，學生們亦能在棒球場上學會不屈不朽的團隊精神帶回日常生活中，因為「棒球只是場景，態度才是靈魂」，長大後成為一段難忘的回憶。</span>
            </div>
            <div className="footer">
               <FooterComponent />
            </div>
         </div>
      </HomeWrapper>
   );
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
