import React from 'react';
import PropTypes from 'prop-types';
import { FooterComponentWrapper } from './FooterComponent.styled';

const FooterComponent = () => (
 <FooterComponentWrapper data-testid="FooterComponent">
    <div className="footer">Copyright © 2024 West Kowloon Baseball Club. All Rights Reserved.</div>
 </FooterComponentWrapper>
);

FooterComponent.propTypes = {};

FooterComponent.defaultProps = {};

export default FooterComponent;
